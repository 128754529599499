import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MasterState } from './masters.reducer';

export const getMasterState = createFeatureSelector<MasterState>('master');

export const getMasterResponse = createSelector(
  getMasterState,
  (state: MasterState) => state.getMasterResponse
);

export const createMasterResponse = createSelector(
  getMasterState,
  (state: MasterState) => state.createMasterResponse
);

export const getAllMasterResponse = createSelector(
  getMasterState,
  (state: MasterState) => state.getAllMasterResponse
);
