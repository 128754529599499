import { createAction, props } from '@ngrx/store';
import { Master } from './masters.models';

// Masters creation
export const Getmaster = createAction(
  '[Master] getMaster',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getMastersSuccess = createAction(
  '[Master] GetMaster Success',
  props<{ getMasterResponse: any }>()
);
export const getMastersFailure = createAction(
  '[Master] GetMaster Failure',
  props<{ getMasterError: any }>()
);

// all  master
export const GetAllmaster = createAction(
  '[Master] getAllMaster',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllMastersSuccess = createAction(
  '[Master] GetAllMaster Success',
  props<{ getAllMasterResponse: any }>()
);
export const getAllMastersFailure = createAction(
  '[Master] GetAllMaster Failure',
  props<{ getAllMasterError: any }>()
);

// Create master
export const createMaster = createAction(
  '[Master] CreateMaster',
  props<{ masterData: Master }>()
);
export const CreateMasterSuccess = createAction(
  '[Master] CreateMaster Success',
  props<{ createMasterResponse: any }>()
);
export const CreateMasterFailure = createAction(
  '[Master] CreateMaster Failure',
  props<{ createMasterError: any }>()
);

// Update master
export const updateMaster = createAction(
  '[Master] UpdateMaster',
  props<{ masterData: Master; id: string }>()
);
export const updateMasterSuccess = createAction(
  '[Master] updateaster Success',
  props<{ updateMasterResponse: any }>()
);
export const updateMasterFailure = createAction(
  '[Master] updateMaster Failure',
  props<{ updateMasterError: any }>()
);

//delete master
export const deleteMaster = createAction(
  '[Master] deleteMaster',
  props<{ id: string }>()
);
export const deleteMasterSuccess = createAction(
  '[Master] deleteMaster Success',
  props<{ deleteMasterResponse: any }>()
);
export const deleteMasterFailure = createAction(
  '[Master] deleteMaster Failure',
  props<{ deleteMasterError: any }>()
);
