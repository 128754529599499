<!-- <div class="container-fluid">
  <div bsModal tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered"> -->
<div class="modal-content">
  <div class="modal-header bg-primary text-white">
    <h5 class="modal-title">Create New Master</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="createMasterForm">
      <div class="mb-3">
        <label>Enter Master Name <span class="required">*</span></label>
        <input type="text" formControlName="name" id="name" class="form-control" />
        @if(submitted && createMasterForm.controls['name'].errors) {
        <div class="invalid-feedback">
          @if(createMasterForm.controls['name'].errors['required']) {
          <div>{{Master_Validations.masterName.required}}</div>}
          @if(createMasterForm.controls['name'].errors['minlength']) {
          <div>{{Master_Validations.masterName.minLengthValidator}}</div>}
          @if(createMasterForm.controls['name'].errors['maxlength']) {
          <div>{{Master_Validations.masterName.MaxLengthValidator}}</div>}
          @if(createMasterForm.controls['name'].errors['pattern']) {
          <div>{{Master_Validations.masterName.patternValidation}}</div>}
        </div>}
      </div>

      <div class="mb-3">
        <label>Master Type <span class="required">*</span></label>
        <ng-select [items]="configData?.data?.types" bindLabel="name" bindValue="id" formControlName="type" id="type"
          [multiple]="false"></ng-select>
        @if(submitted && createMasterForm.controls['type'].errors) {
        <div class="invalid-feedback">
          @if(createMasterForm.controls['type'].errors['required']) {
          <div>{{Master_Validations.masterType.required}}</div>}
        </div>}

      </div>


    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    @if(masterData?.name){ <button type="button" class="btn btn-primary waves-effect waves-light"
      (click)="CreateMaster('Update')">Update Master</button>}
    @else{ <button type="button" class="btn btn-primary waves-effect waves-light" (click)="CreateMaster('Create')">Save
      Master</button> }
  </div>
</div><!-- /.modal -->
<!-- </div>
  </div>
</div> -->