<app-rp-loader></app-rp-loader>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <!-- <h4 class="mb-0 font-size-18">Masters And Suppliers</h4> -->
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <!-- <div class="page-title-right">
              <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active"><a href="javascript: void(0);">Home</a>
                  </li>
                  
                  <li class="breadcrumb-item">Create New
                  </li>
              
              </ol>
            </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4"></h4> -->
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper sm-stepper">

              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>Masters</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){

                <section class="">

                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">Masters</h4>
                  </div>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="mastergridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [groupable]="false" [selectable]="selectableSettings" [pageable]="{
                                        buttonCount: buttonCount,
                                        info: info,
                                        type: type,
                                        pageSizes: pageSizes,
                                        previousNext: previousNext
                                      }" [reorderable]="true" filterable="menu" [filter]="masterstate.filter"
                      [sort]="masterstate.sort" [columnMenu]="{ filter: true }" [resizable]="true"
                      kendoGridSelectBy="id" scrollable="scrollable"  (filterChange)="onFilterChange($event)"
                      (sortChange)="onSortChange($event,'master')" (pageChange)="masterPageChange($event)"
                      (selectionChange)="selectionChanged($event)" (dataStateChange)="dataStateChange($event)">
                      <ng-template kendoGridToolbarTemplate>
                        <div>
                          <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedMasterValue"  [clearButton]="true"
                          (valueChange)="clearSearchValueChange($event)"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMasterFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-primary btn-sm me-2" (click)="addMaster()">Add Master </button>
                          <!-- <button class="btn btn-light me-2" (click)="exportMaster('Pdf')">
                            <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->
                          <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="exportMaster('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="type.name" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                      <kendo-grid-command-column [columnMenu]="false" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex">
                            <!-- Edit Master Button -->
                            <button kendoTooltip title="Go to Master Edit"
                              class="btn btn-primary btn-sm d-flex align-items-center"
                              (click)="editMaster(dataItem)">
                              <i class="fas fa-edit"></i>
                            </button>
                      
                            <!-- Delete Master Button -->
                            <button kendoTooltip title="Delete Master"
                              class="btn btn-danger btn-sm d-flex align-items-center m-lg-auto ms-3 p-2"
                              (click)="deleteMaster(dataItem)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>
                      

                    </kendo-grid>
                  </div>
                  <!--master download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionMasters.length>0 ? mySelectionMasters:allMastersList.length>0?allMastersList:mastergridData.data"
                      #masterGrid>
                      <kendo-grid-column field="type.name" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                      <!-- <kendo-grid-pdf fileName="masters.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf> -->
                      <kendo-grid-excel fileName="masters.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>
                  <!--master download buttons end-->


                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        cdkStepperNext>Next</button></li>
                  </ul>

                </section>


                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Suppliers</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section>

                  <div class="row mb-2 d-sm-block d-md-none">
                    <h4 class="card-title mb-4 text-decoration-underline">Suppliers</h4>
                  </div>

                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">

                    <kendo-grid [data]="suppliersgridData" [pageSize]="supplierPageSize" [skip]="supplierskip"
                      [sortable]="true" [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext
                  }" [reorderable]="true" [selectable]="selectableSettings" filterable="menu"
                      [filter]="supplierstate.filter" [sort]="supplierstate.sort" [columnMenu]="{ filter: true }"
                      [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
                      (pageChange)="supplierPageChange($event)" (dataStateChange)="supplierdataStateChange($event)"
                      (selectionChange)="supplierselectionChanged($event)"
                      (filterChange)="onSupplierFilterChange($event)" (sortChange)="onSortChange($event,'supplier')">

                      <ng-template kendoGridToolbarTemplate>
                        <div>
                          <kendo-textbox [style.width.px]="250" [(ngModel)]="searchedSupplierValue"  [clearButton]="true"
                          (valueChange)="clearSearchValueChangeSupplier($event)"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onSupplierFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device">
                          <button class="btn btn-primary btn-sm me-2" (click)="addSuppliers()">Add Supplier
                          </button>
                          <!-- <button kendoGridPDFCommand class="btn btn-light me-2" (click)="exportSupplier('Pdf')">
                            <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                          <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="exportSupplier('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="master.name" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                      <kendo-grid-command-column [columnMenu]="false" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex">
                            <!-- Edit Supplier Button -->
                            <button kendoTooltip title="Go to Supplier Edit"
                              class="btn btn-primary btn-sm d-flex align-items-center ms-2 p-2"
                              (click)="editSupplier(dataItem)">
                              <i class="fas fa-edit"></i>
                            </button>
                      
                            <!-- Delete Supplier Button -->
                            <button kendoTooltip title="Delete Supplier"
                              class="btn btn-danger btn-sm d-flex align-items-center m-lg-auto ms-2 p-2"
                              (click)="deleteSupplier(dataItem)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>
                      

                    </kendo-grid>
                  </div>
                  <!--supplier download buttons start-->
                  <!-- <kendo-excelexport [data]="mySelectionSupplier.length>0 ? mySelectionSupplier:suppliersgridData.data"
                    fileName="Supplier.xlsx" #supplierexcelexport>
                    <kendo-excelexport-column field="master.name" title="Type"></kendo-excelexport-column>
                    <kendo-excelexport-column field="name" title="Name"></kendo-excelexport-column>
                  </kendo-excelexport> -->
                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionSupplier.length>0 ? mySelectionSupplier:allSupplierList.length>0?allSupplierList:suppliersgridData.data"
                      #supplierGrid>
                      <kendo-grid-column field="master.name" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                      <kendo-grid-pdf fileName="Supplier.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="Supplier.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>
                  <!--supplier download buttons end-->

                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                  </ul>

                </section>
                }
              </cdk-step>

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>