import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierState } from './suppliers.reducer';

export const getSuppliersState =
  createFeatureSelector<SupplierState>('supplier');

export const getSuppliersResponse = createSelector(
  getSuppliersState,
  (state: SupplierState) => state.getSuppliersResponse
);

export const createMasterResponse = createSelector(
  getSuppliersState,
  (state: SupplierState) => state.createSuppliersResponse
);

export const getAllSuppliersResponse = createSelector(
  getSuppliersState,
  (state: SupplierState) => state.getAllSuppliersResponse
);
