<!-- <div bsModal tabindex="-1">
    <div class="modal-dialog modal-dialog-centered"> -->
<div class="modal-content">
  <div class="modal-header ">
    <h5 class="modal-title">Create New Supplier</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="createSupplierForm">
      <div class="mb-3">
        <label>Select Master <span class="required">*</span></label>
        <ng-select [items]="configData?.data?.masters" bindLabel="name" formControlName="masterId" bindValue="id"
          [multiple]="false"></ng-select>
        @if(submitted && createSupplierForm.controls['masterId'].errors) {
        <div class="invalid-feedback">
          @if(createSupplierForm.controls['masterId'].errors['required']) {
          <div>{{Supplier_Validations.masterId.required}}</div>}
        </div>}
      </div>

      <div class="mb-3">
        <label>Enter Supplier Name <span class="required">*</span></label>
        <input type="text" formControlName="name" id="name" class="form-control" />
        @if(submitted && createSupplierForm.controls['name'].errors) {
        <div class="invalid-feedback">
          @if(createSupplierForm.controls['name'].errors['required']) {
          <div>{{Supplier_Validations.SupplierName.required}}</div>}
          @if(createSupplierForm.controls['name'].errors['minlength']) {
          <div>{{Supplier_Validations.SupplierName.minLengthValidator}}</div>}
          @if(createSupplierForm.controls['name'].errors['maxlength']) {
          <div>{{Supplier_Validations.SupplierName.MaxLengthValidator}}</div>}
          @if(createSupplierForm.controls['name'].errors['pattern']) {
          <div>{{Supplier_Validations.SupplierName.patternValidation}}</div>}
        </div>}
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    @if(supplierData?.name){ <button type="button" class="btn btn-primary waves-effect waves-light"
      (click)="CreateSupplier('Update')">Update Supplier</button>}
    @else{ <button type="button" class="btn btn-primary waves-effect waves-light"
      (click)="CreateSupplier('Create')">Save Supplier</button> }
  </div>
</div><!-- /.modal -->
<!-- </div>
  </div> -->