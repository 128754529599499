import { LoaderService } from '../../../core/services/loader.service';
import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createMaster,
  updateMaster,
} from '../../../store/Masters/masters.actions';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
@Component({
  selector: 'app-rp-create-master',
  templateUrl: './iot-create-master.component.html',
  styleUrl: './iot-create-master.component.scss',
})
export class IotCreateMasterComponent {
  configData: any;
  createMasterForm!: UntypedFormGroup;
  submitted: boolean = false;
  @Input() masterData: any;

  Master_Validations = Validations.MasterCreatePage;
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder // public activeModal: NgbActiveModal
  ) {
    this.getStoreInfo();
  }

  getStoreInfo() {}
  dismissModal(): void {
    this.modalService.hide();
  }

  CreateMaster(type: string): void {
    this.submitted = true;
    if (this.createMasterForm.valid) {
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.createMasterForm.value);
      if (type == 'Create') {
        this.store.dispatch(
          createMaster({ masterData: this.createMasterForm.value })
        );
      } else if (type == 'Update') {
        console.log(this.createMasterForm.value);
        this.store.dispatch(
          updateMaster({
            masterData: this.createMasterForm.value,
            id: this.masterData.id,
          })
        );
      }
      this.modalService.hide();
    }
  }

  get f() {
    return this.createMasterForm.controls;
  }

  ngOnInit(): void {
    this.createMasterForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z0-9\s]*$/),
        ],
      ],
      type: [null, Validators.required],
    });
    this.getAllConfigData();
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;

        //update master binding data
        // const matchedType = this.configData?.data?.types.find(
        //   (type: any) => type.name === this.masterData.type
        // );

        this.createMasterForm.patchValue({
          name: this.masterData?.name ? this.masterData.name : '',
          type: this.masterData?.type?.id ? this.masterData.type.id : '',
        });
        //
      }
      console.log('Types', this.configData.data.types);
    });
  }
}
