import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IotMastersComponent } from './iot-masters/iot-masters.component';

const routes: Routes = [
  // { path: 'masters', component: IotMastersComponent },
  { path: 'masters', component: IotMastersComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MastersRoutingModule {}
